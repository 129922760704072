/* CSS File for projects page */

/* Projects Section */
.projects {
  display: inline-flex;
  align-items: center;
  margin: 0 5%;
  height: 70vh;
}

.projects img {
  width: 35%;
}

/* Left Project */
.left-section {
  width: 20%;
  animation: fade-in 2s ease-in;
}

.left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left-container img {
  width: 40%;
  padding: 5px;
  border: 2px solid var(--grey-color);
  border-radius: 10px;
  transition: transform ease-in-out 1.5s;
}

.left-container img:hover {
  transform: scale(1.5);
}

.left-container h3 {
  margin: 10px 0;
  border-bottom: 2px solid var(--bright-color);
}

.left-container span {
  color: var(--bright-color);
}

.left-container p {
  text-align: center;
  margin-top: 5px;
}

.left-container .leftIcon {
  font-size: 12px;
  margin-left: 10px;
  color: var(--grey-color);
}

.left-container .leftIcon:hover {
  color: var(--bright-color);
}

/* Middle Header */
.middle-section {
  width: 20%;
  height: 100vh;
  animation: fade-in 3s ease-in;
}

.middle-section h2 {
  margin-top: 100%;
  transform: rotate(270deg);
  font-size: 80px;
  color: var(--dark-color);
  text-shadow: -1px 0 var(--bright-color), 0 1px var(--bright-color),
    1px 0 var(--bright-color), 0 -1px var(--bright-color);
}

/* Right Project Section */
.right-section {
  width: 60%;
  animation: fade-in 4s ease-in;
}

.right-container {
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .projectPopUpGallery {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;
    width: auto;
    /* background-color: #191716; */
    padding: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    .popUpImageContainer {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 800px; */
      /* height: 300px; */
      margin: 10px 0;
      gap: 12px;
      color: white;

      .imagePopUpCard {
        position: relative;
        overflow: hidden;

        img {
          height: auto;
          width: 100%;
          object-fit: cover;
        }
      }
    }

    .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background-color: var(--bright-color);
      border-radius: 18px;
      border: 1px solid var(--bright-color);
    }
  }

  .inner-project-container {
    position: relative;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-gap: 20px;
    justify-content: center;
    text-align: center;

    .projectCard {
      position: relative;
      /* border: 1px solid var(--bright-color); */
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      /* border-radius: 10px; */

      img {
        width: 100%;
        height: 100%;
        /* border-radius: 10px; */
        object-fit: cover;
      }

      &.cardone {
        grid-column: 1 / span 2;
        grid-row: span 4;
      }
      &.cardtwo {
        grid-column: span 2;
        grid-row: span 1;
      }
      &.cardthree {
        grid-column: span 1;
        grid-row: span 1;
      }
      &.cardfour {
        grid-column: span 1;
        grid-row: span 2;
      }
      &.cardfive {
        grid-column: span 1;
        grid-row: span 3;
      }
      &.cardsix {
        grid-column: span 3;
        grid-row: span 2;
      }
      &.cardseven {
        grid-column: span 2;
        grid-row: span 2;
      }
      &.cardeight {
        grid-column: span 2;
        grid-row: span 2;
      }
      &.cardnine {
        grid-column: span 1;
        grid-row: span 2;
      }
      &.cardten {
        grid-column: span 2;
        grid-row: span 6;
      }
      &.cardeleven {
        grid-column: span 3;
        grid-row: span 6;
      }

      .projectMetadata {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -100%;
        height: 100%;
        width: auto;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: bottom 0.3s ease-in-out;

        .projectMetadataBtn {
          cursor: pointer;
          margin: 10px 0;
          background-color: transparent;
          text-transform: uppercase;
          border: 1px solid var(--bright-color);
          background: linear-gradient(
            to right,
            var(--grey-color) 50%,
            transparent 50%
          );
          background-size: 200% 100%;
          background-position: right bottom;
          border-radius: 20px;
          padding: 10px;
          transition: all 0.3s ease-in-out;

          &:hover {
            background-position: left bottom;
            scale: 1.02;
          }

          &:active {
            scale: 0.9;
          }
        }

        .projectIcons {
          font-size: 20px;
          padding-top: 20px;

          a {
            margin: 10px;
          }
        }
      }

      &:hover {
        .projectMetadata {
          bottom: 0;
        }
      }
    }
  }
}

/* Animation */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.scrollBtns {
  margin-top: 20px;
}

.prevBtn,
.nextBtn {
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  border: none;
  margin: 0 10px;
}

.prevBtn {
  left: -10px;
}

.nextBtn {
  right: -10px;
}

.prevBtn:hover,
.nextBtn:hover {
  color: var(--bright-color);
}

.nextBtn:active,
.prevBtn:active {
  animation: bounce 0.5s ease-in-out;
}

@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-25%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  97% {
    transform: translateY(0%);
  }
  99% {
    transform: translateY(-3%);
  }
  100% {
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 650px) {
  .projects {
    display: block;
    margin: 0;
    height: auto;
  }

  .projects h3 {
    animation: fade-in 2s ease-in;
  }

  .projects p {
    font-size: 16px;
    animation: fade-in 3s ease-in;
  }

  .projects img {
    width: 30%;
    margin: 10px 0;
    animation: fade-in 1s ease-in;
  }

  .left-container p {
    margin: 10px 40px;
  }

  .middle-section {
    height: fit-content;
  }

  .middle-section h2 {
    transform: rotate(0deg);
    font-size: 60px;
    text-align: center;
    margin: 0;
    animation: fade-in 4s ease-in;
  }

  .right-section {
    margin: 0;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  .left-section,
  .middle-section {
    width: fit-content;
    margin: 30px 0 0;
  }

  .right-section {
    width: 100%;
  }
  .right-container {
    margin: 0 30px;

    .projectPopUpGallery {
      width: 350px;
      padding: 10px;
    }

    .inner-project-container {
      .projectCard {
        &.cardone {
          grid-column: span 6;
          grid-row: span 8;
        }
        &.cardtwo {
          grid-column: span 6;
          grid-row: span 3;
        }
        &.cardthree {
          grid-column: span 3;
          grid-row: span 3;
        }
        &.cardfour {
          grid-column: span 3;
          grid-row: span 2;
        }
        &.cardfive {
          grid-column: span 6;
          grid-row: span 2;
        }
        &.cardsix {
          grid-column: span 6;
          grid-row: span 2;
        }
        &.cardseven {
          grid-column: span 6;
          grid-row: span 6;
        }
        &.cardeight {
          grid-column: span 6;
          grid-row: span 3;
        }
        &.cardnine {
          grid-column: span 3;
          grid-row: span 3;
        }
        &.cardten {
          grid-column: span 3;
          grid-row: span 3;
        }
        &.cardeleven {
          grid-column: span 6;
          grid-row: span 2;
        }
      }
    }
  }

  .individual-project h3 {
    width: 80%;
    margin-bottom: 10px;
  }

  .project {
    margin-bottom: 20px;
  }

  .mobile-project h3 {
    width: 80%;
    margin-bottom: 20px;
  }

  .mobile-project img {
    display: none;
  }

  .mobile-project-inner {
    flex-direction: column;
  }
}
