.chatbotContainer {
  .popUpBtn {
    cursor: pointer;
    background-color: var(--dark-color);
    color: var(--light-color);
    border: 2px solid var(--grey-color);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    img {
      border-radius: 50%;
      padding: 3px;
      margin: 0 5px;
      z-index: 1;
      border: 1px solid var(--bright-color);
    }
  }

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .chatContainer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 600px;
    width: 550px;
    z-index: 20;
    opacity: 0;
    border: 2px solid var(--bright-color);
    border-radius: 10px;
    background-color: var(--dark-color);
    visibility: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.open {
      opacity: 1;
      visibility: visible;
    }

    .chatBox {
      border: 1px solid var(--bright-color);
      background-color: var(--dark-color);
      padding: 10px;
      margin: 10px;
      height: 500px;
      width: 500px;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;

      li {
        background-color: var(--dark-color);
        border: 4px solid var(--grey-color);
        border-radius: 15px;
        padding: 10px;
        width: 80%;
        margin: 15px 5px;
      }

      li:nth-child(2n) {
        /* color: var(--bright-color); */
        border: 5px solid var(--bright-color);
        text-align: right;
        margin-left: auto;
      }
    }

    .questionContainer {
      width: 100%;
      margin: 10px;
      position: fixed;
      bottom: 0;

      .chatbotInput {
        border: none;
        width: 460px;
        height: 50px;
        padding: 5px 10px;
        margin-left: 23px;
      }

      .chatSubmitBtn {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        color: var(--bright-color);
        font-size: 32px;
        background-color: transparent;
      }
    }

    .closeModal {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background-color: var(--bright-color);
      border-radius: 18px;
      border: 1px solid var(--dark-color);
    }
  }
}

@media (max-width: 1200px) {
  .chatbotContainer {
    .chatContainer {
      width: 350px;
      left: 47%;

      .chatBox {
        height: 500px;
        width: 330px;
        overflow: scroll;
        margin: 0;
        margin-top: 10px;
      }

      .questionContainer {
        .chatbotInput {
          width: 280px;
          margin: 10px;
          margin-left: 0;
        }
      }
    }
  }
}
